var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BCard',{attrs:{"title":"Ações"}},[_c('div',[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":""},on:{"change":_vm.swtichAllowInactivate},model:{value:(_vm.allowInactivates),callback:function ($$v) {_vm.allowInactivates=$$v},expression:"allowInactivates"}},[_vm._v(" Mostrar inativos? ")])],1),_c('div',{style:({
        overflowX: 'auto',
        width: '100%',
        display: 'flex',
        marginBottom: '20px',
        border: '1px solid',
        borderColor: '#d5d5d5',
        borderRadius: '4px',
        paddingLeft: '5px'
      })},_vm._l((_vm.users),function(user){return _c('div',{key:user.id,style:({
          display: 'flex',
          flexDirection: 'row',
          gap: '8px',
          alignItems: 'center'
        })},[_c('input',{attrs:{"type":"checkbox","checked":""},on:{"change":function($event){(_vm.switchedToSelect = true), _vm.getPage(user.member_id)}}}),_c('span',{staticClass:"cutom-bullet",style:({
            borderRadius: '50%',
            display: 'block',
            color: user.color,
            background: user.color,
            width: '15px',
            height: '15px'
          }),on:{"click":function($event){return _vm.handleClickBullet(user)}}}),_c('div',{style:({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center'
          })},[_c('p',{style:({
              marginRight: '10px',
              marginTop: '12px'
            })},[_vm._v(" "+_vm._s(user.username)+" ")])])])}),0)]),_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('b-card',{attrs:{"title":"Custos estimados e reais por tarefa & projeto"}},[_c('div',{staticStyle:{"overflow-x":"auto"}},[_c('b-table',{staticClass:"text-left",attrs:{"responsive":"sm","small":"","id":"my-table","hover":"","striped":"","fields":_vm.fields,"items":_vm.items,"per-page":10,"current-page":0,"busy":_vm.isBusy,"show-empty":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"p-2"},[_c('b-alert',{attrs:{"show":"","variant":"primary"}},[_c('p',{staticClass:"text-center"},[_c('br'),_vm._v(" Não existe "),_c('strong',[_vm._v("Tarefa")]),_vm._v(" dentro do escopo deste escritório ")]),_c('hr')])],1)]},proxy:true},{key:"cell(title)",fn:function(ref){
            var item = ref.item;
return [_c('small',[_c('strong',[_vm._v(_vm._s(item.title))])]),_c('br'),_c('small',[_vm._v(" "+_vm._s(item.notes ? 'Obs.: ' + item.notes : 'Sem descrição'))])]}},{key:"cell(project)",fn:function(ref){
            var item = ref.item;
return [_c('small',[_c('strong',[_vm._v("#"+_vm._s(item.project.project_code))])]),_c('p',[_vm._v(_vm._s(item.project.project_name))])]}},{key:"cell(actions)",fn:function(props){return [_c('div',{on:{"click":function($event){return _vm.handleUpdateModal(props.item)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.lefttop.v-primary",modifiers:{"hover":true,"lefttop":true,"v-primary":true}}],attrs:{"title":"Editar fornecedor","icon":"EditIcon","size":"15"}}),_vm._v("Editar ")],1)]}},{key:"cell(username)",fn:function(ref){
            var item = ref.item;
return [_c('div',[_c('div',{style:({
                    display: 'flex',
                    alignItems: 'center'
                  })},[_c('span',{style:({
                      borderRadius: '50%',
                      //   display: 'block',
                      background: item.member.color,
                      width: '10px',
                      height: '10px'
                    })}),_c('p',{style:({
                      height: 'auto',
                      paddingLeft: '5px',
                      paddingTop: '10px',
                      marginBottom: '0px',
                      fontSize: '12px'
                    })},[_vm._v(" "+_vm._s(item.member.access.user.username)+" ")])]),_c('small',[_vm._v(_vm._s(item.member.access.user.email))])])]}},{key:"cell(date)",fn:function(ref){
                    var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm.dateFormatter(item.date, 'dd/MM/yyyy')))])]}},{key:"cell(start)",fn:function(ref){
                    var item = ref.item;
return [_c('small',[_vm._v(" "+_vm._s(item.start.slice(0, 5)))])]}},{key:"cell(end)",fn:function(ref){
                    var item = ref.item;
return [_c('small',[_vm._v(_vm._s(item.end.slice(0, 5)))])]}},{key:"cell(duration)",fn:function(ref){
                    var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm.eventDuration(item.end, item.start)))])]}},{key:"cell(dirEstCost)",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" R$ "+_vm._s(_vm.numberToMonetary(item.costHour / item.hourPerMonth * _vm.eventDurationForCalc(item.end, item.start)))+" ")]}}])})],1),_c('b-pagination',{staticClass:"justify-content-center mt-1",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"aria-controls":"my-table"},on:{"change":_vm.getPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('div',{staticClass:"mr-2 d-flex justify-content-end"},[_c('strong',[_vm._v("Total de Tarefas: "+_vm._s(_vm.totalRows)+" ")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }